const locale_product_pages = {
  reservation_btn: {
    hu: "Érdeklődöm",
    at: "Anfragen",
  },
  product_card_btn: {
    hu: "Megnézem",
    at: "Ansehen",
  },
  shipping_title: {
    hu: "Szállítási információk",
    at: "Lieferinformationen",
  },
  shipping_infos: {
    hu: "<p>A szállítási költség magába foglalja a ki és visszaszállítást, valamint a játék üzembe helyezését és a rendezvényt követő összerakását.</p><p><br /></p><p>0-5 km: <strong>3.000 Ft</strong></p><p>5-10 km: <strong>5.000 Ft</strong></p><p>10-20 km: <strong>8.000 Ft</strong></p><p>20-30 km: <strong>11.000 Ft</strong></p><p>30-40 km: <strong>14.000 Ft</strong></p><p>40 km felett: <strong>400 Ft / km</strong></p><p><br /></p><p><strong>Személyzet:</strong><br />A légváraink mellé felügyelet külön kérhető. Egész napos rendezvény esetén <strong>1600 Ft / fő / óra</strong></p>",
    at: "<p>Versandkosten beinhalten Aus- und Rückkehr sowie das Zusammenstellen des Spiels und das </p><p>Zusammenstellen nach der Veranstaltung. </p><p><br /></p><p>0-5 km: 10 EUR  </p><p>5-10 km: 15 EUR  </p><p>10-20 km: 21 EUR  </p><p>20-30 km: 26 EUR  </p><p>30-40 km: 32 EUR  </p><p>40 km felett: <strong>0,3 cent / km </strong> </p><p><br /></p><p>Personal </p><p>Zusätzlich zu unseren Luftschlossen können Sie eine Aufsichtpersonal angefordert werden.  5 </p><p>EUR / Person / Stunde </p>",
  },
  rental_text: {
    hu: "Bérleti díja (nagyobb rendezvények esetén):",
    at: "Mietpreis (Im Falle einer größeren Veranstaltung):",
  },
  rental_text_shipping: {
    hu: "A szállítással kapcsolatos információ a lap alján megtalálható!",
    at: "Lieferungsinformationen finden Sie unten auf der Seite!",
  },
  brutto_text: {
    hu: "(bruttó ár)",
    at: "(Brutto)",
  },
  combined_text: {
    hu: "Együtt jobban megéri",
    at: "Gemeinsam besser",
  },
  separated_parts_text: {
    hu: "A játék részei külön is bérelhetőek",
    at: "Teile des Spiels können separat gemietet werden",
  },
};

export default locale_product_pages;
