import { combineReducers, configureStore } from "@reduxjs/toolkit";
// import servicesReducers from "./servicesSlices/servicesReducers";
import langReducers from "./langSlices/langReducers";
import productsReducers from "./productsSlices/productsReducers";
import galleryReducers from "./gallerySlices/galleryReducers";
import aboutUsReducers from "./aboutUsSlices/aboutUsReducers";
import formReducers from "./formSlice/formReducers";
const reducer = combineReducers({
  //   services: servicesReducers,
  lang: langReducers,
  products: productsReducers,
  gallery: galleryReducers,
  aboutUs: aboutUsReducers,
  form: formReducers,
});

export const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: true,
});
