const locale_navitems = {
  homepage: {
    hu: {
      title: "Kezdőlap",
      path: "",
    },
    at: {
      title: "Startseite",
      path: "at",
    },
  },
  about_us: {
    hu: {
      title: "Rólunk",
      path: "rolunk",
    },
    at: {
      title: "Über uns",
      path: "at/uber-uns",
    },
  },
  air_castles_page: {
    hu: {
      title: "Légváraink",
      path: "legvarak",
    },
    at: {
      title: "Luftschlösser",
      path: "at/luftschlosser",
    },
  },

  gallery_page: {
    hu: {
      title: "Galéria",
      path: "galeria",
    },
    at: {
      title: "Galerie",
      path: "at/galerie",
    },
  },
  contact_page: {
    hu: {
      title: "Kapcsolat",
      path: "kapcsolat",
    },
    at: {
      title: "Kontakt",
      path: "at/kontakt",
    },
  },
  header_text: {
    hu: "Életre szóló élmény kicsiknek és nagyoknak",
    at: "Ein einmaliges Erlebnis für Jung und Alt",
  },
};

export default locale_navitems;
